import * as React from 'react'
import { Component } from 'react'
import { RouteComponentProps } from "react-router-dom";
import { SecondaryTitle, EditDelBtns } from '@app/components';
import { CampusForm } from './components';
import { withRouter, GLGlobal, connect, GLAction, GLLocale } from 'gl-commonui';
import { StateType } from '@app/states';
import { get, update, remove, goToList, catAddress } from '@app/states/school/campus';
import { SchoolLocale } from '@app/locales/localeid';
import { GSSchoolAction } from '@app/util/enum';
import { PageTitleDelBtn } from '@app/components/school/school-page-title-btn';
import { Modal } from 'antd-min';
import { StaticContext } from 'react-router';
import { CampusModel } from '@app/service/schools';

interface CampusNewPageProps {
    model: CampusModel
    get: (d) => void
    update: (d) => void
    remove: (d) => void
    goToList: () => void
    schoolId: string
    campusId: string
    change: (d) => void
    isCartEmpty: boolean
}

@withRouter
@connect(({ campus: { model, isCartEmpty }, school: { current: { campusId } } }: StateType) => ({
    model,
    campusId,
    isCartEmpty
}), {
    get,
    update,
    remove,
    goToList,
})
export class CampusEditPage extends Component<RouteComponentProps<any> & CampusNewPageProps, any> {

    constructor(props) {
        super(props);
        this.state = {
            disableSaveButton: true,
            previousDisabledState: false
        };
    }

    componentWillMount() {
        const { schoolId, campusId } = this.props.match.params;
        this.props.get({ schoolId, campusId });
    }

    componentDidUpdate(prevProps: Readonly<RouteComponentProps<any, StaticContext, any> & CampusNewPageProps>, prevState: Readonly<any>, snapshot?: any): void {
        if (typeof prevProps.model.disabled != "boolean" && typeof this.props.model.disabled == "boolean") {
            this.setState({ previousDisabledState: this.props.model.disabled });
        }
    }

    getPreviousDisabledState = () => {
        return this.state.previousDisabledState;
    }

    enableSaveButton = () => {
        if (!this.state.disableSaveButton) {
            return;
        }

        this.setState({ disableSaveButton: false });
    }

    render() {
        const { update, remove, goToList, model } = this.props;
        return <div className='content-layout campus-crud campus-crud-edit'>
            <div>
                <SecondaryTitle title={SchoolLocale.CampusEditTitle} >
                    <GLAction action={GSSchoolAction.DeleteCampus}>
                        <PageTitleDelBtn type="delete" className="delete" labelId={SchoolLocale.BtnDelete} deleteTitleId={SchoolLocale.CampusDelete} delete={updatePrimaryBy(remove, this.props, this.getPreviousDisabledState, true)} />
                    </GLAction>
                </SecondaryTitle>
                <CampusForm isEditScreen={true} model={model} isNewCampus={false} enableSaveButton={this.enableSaveButton} handleSubmit={updatePrimaryBy(update, this.props, this.getPreviousDisabledState)}>
                    <EditDelBtns isDisabled={this.state.disableSaveButton} cancelTitle={SchoolLocale.BtnBackList} delete={updatePrimaryBy(remove, this.props, this.getPreviousDisabledState, true)} localeId={SchoolLocale.CampusDelete} submitAction={GSSchoolAction.SaveCampus} deleteAction={GSSchoolAction.DeleteCampus} />
                </CampusForm>
            </div>
        </div>
    }
}
function updatePrimaryBy(callback, props, getPreviousDisabledState, isDelete?) {
    const fmtMsg = GLGlobal.intl.formatMessage;
    const { model } = props;
    model.isCartEmpty = props.isCartEmpty;
    return (d) => {
        const { model, campusId: primaryId } = props;
        const id = (d || model).id;
        const disabled = (d || model).disabled;
        const isCartEmpty = (d || model).isCartEmpty;
        if (!isDelete && id && disabled && disabled !== getPreviousDisabledState() && !isCartEmpty) {
            Modal.confirm({
                title: fmtMsg({ id: SchoolLocale.CampusDisabledPrompt }),
                okText: fmtMsg({ id: GLLocale.Ok }),
                cancelText: fmtMsg({ id: GLLocale.Cancel }),
                onOk: () => { callback({ ...d, previousDisabledState: getPreviousDisabledState() }, (d || model).id === primaryId || isDelete); }
            })
        }
        else {
            callback({ ...d, previousDisabledState: getPreviousDisabledState() }, (d || model).id === primaryId || isDelete);
        }

    }
}
