import * as React from 'react'
import { Component } from 'react'
import { RouteComponentProps, Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { SchoolLocale, GSAdminLocale, LicenseTypeLocale } from "../../../../locales/localeid";
import { Input, Select, Checkbox, Modal, Button, Table } from 'antd-min';
import { GLGlobal, GLForm, GLFormComponentProps, FormItemsLayout, FormHelper, ComparisonOperator, alignPop, withRouter, connect, isGuid} from 'gl-commonui';
import { CampusModel, CampusUpdateStatusModel, ICampusService } from '../../../../service/school/campus';
import {lazyInject, TYPES, onSubmit, textareaValider, extendForm, GSSchoolAction, ContextHelper, ClassHelper, GSAdminAction, fmtMsg, CONSTS, LicenseTypeValueNameMap} from '../../../../util';
import TextArea from 'antd/lib/input/TextArea';
import { fetchTagListAction } from '@app/states/tag-manager/tag-manager';
import { StateType } from '@app/states';
import {LicenseTypes} from '@app/util/enum';
import { ISchoolService } from '@app/service/schools';
import { TagEntity, TagListModel } from '@app/service/tag-manager';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { updateCampusLicenseError } from '@app/states/school/campus';
import { RoleHelper } from '@app/util/roleHelper';
import "../form-view.less";

export interface CampusFormProps {
    handleSubmit: (e) => void,
    enableSaveButton?: () => void,
    campusUpdateErrorStatus?: CampusUpdateStatusModel,
    model?: CampusModel,
    tagList?: { [key: string]: TagListModel[] };
    fetchTagListAction: typeof fetchTagListAction;
    updateCampusLicenseError: (d) => void;
    isEditScreen?: boolean;
    isNewCampus?: boolean;
}

let heightestRole;
interface formViewState {
    masterGSLicenses?: number[];
    currentCampusGSLicenses?: number[];
    masterCampusGSLicenses?: number[];
    selectedValues: CheckboxValueType[];
    isDisableModalVisible: boolean;
}

const GSNexus = "Nexus";
const GSHybridNexus = "Classic/Nexus Hybrid";
const GSHybridConnectNexus = "Connect/Nexus Hybrid";
@withRouter
@connect(({ campus : { campusUpdateErrorStatus }, tagManager: { tagList } }: StateType) => ({
    tagList,
    campusUpdateErrorStatus
}), {
    fetchTagListAction,
    updateCampusLicenseError
})

@GLForm.create()
export class CampusForm extends Component<CampusFormProps & GLFormComponentProps & RouteComponentProps<any>, formViewState> {
    @lazyInject(TYPES.ISchoolService)
    service: ISchoolService;
    @lazyInject(TYPES.ICampusService)
    campusService: ICampusService;
    
    constructor(props) {
        super(props);
        this.state = {
            masterGSLicenses: [],
            masterCampusGSLicenses: [],
            currentCampusGSLicenses: [],
            selectedValues:[],
            isDisableModalVisible: false,
        };
    }
    keyValueMap(value) {
        switch(value) {
            case GSNexus: return LicenseTypes.Nexus;
            case GSHybridNexus: return LicenseTypes.ClassicNexusHybrid;
            case GSHybridConnectNexus: return LicenseTypes.ConnectNexusHybrid;
        }
    }

    onFormChange = () => {
        if(this.props.isEditScreen) {
            this.props.enableSaveButton();
        }
    }

    getLicensefromSchoolandCampus = () => {
        const { masterGSLicenses } = this.state;
        const licenseTypes = [];
        const { masterCampusGSLicenses } = this.state;

        if(masterCampusGSLicenses.length > 0)
        {
            return masterCampusGSLicenses;
        }
        // if licenses are not present in campus then we will show all the licenses from school level
        if (masterGSLicenses && masterGSLicenses.length > 0) {
            masterGSLicenses.forEach((licenseType) => {
                switch(licenseType) {
                    case LicenseTypes.Nexus:
                        licenseTypes.push(3);
                        break;
                    case LicenseTypes.ClassicNexusHybrid:
                        licenseTypes.push(5);
                        break; 
                    case LicenseTypes.ConnectNexusHybrid:
                        licenseTypes.push(6);
                        break;
                    default:
                        break;
                }
            })
            return licenseTypes;
        }
        return licenseTypes;
    }

    isLicenseOptionsDisabled = (): boolean => {
        const { masterGSLicenses } = this.state;
        const requiredLicenses = [LicenseTypes.Nexus, LicenseTypes.ClassicNexusHybrid, LicenseTypes.ConnectNexusHybrid];
    
        if (!masterGSLicenses || masterGSLicenses.length === 0) {
            return true; // disable if masterGSLicenses is empty or not defined
        }
        // Check if all requiredLicenses are present in masterGSLicenses
        return !requiredLicenses.every((licenseType) => masterGSLicenses.includes(licenseType));
    }

    getLicenseOptions = () => {
        const { masterGSLicenses } = this.state;
        const licenseTypes = [
            { label: fmtMsg({ id: CONSTS.LicenseType.get(LicenseTypes.Nexus) }), value: 3 },
            { label: fmtMsg({ id: CONSTS.LicenseType.get(LicenseTypes.ClassicNexusHybrid) }), value: 5 },
            { label: fmtMsg({ id: CONSTS.LicenseType.get(LicenseTypes.ConnectNexusHybrid) }), value: 6 }
        ];
        
        return licenseTypes.map(option => ({
            ...option,
            disabled: heightestRole == 'SchoolAdmin' ? true : !masterGSLicenses.includes(option.value)
        }));
    }
    
    handleCheckboxChange = (checkedValues) => {
        this.setState({ currentCampusGSLicenses: checkedValues });
        this.onFormChange();
    };

    async componentDidMount() {
        heightestRole = RoleHelper.getMaxRoleOfCurrentUser()
        const { regionId, schoolId, campusId } = this.props.match.params;
    
        this.props.fetchTagListAction({ regionId: regionId, entity: TagEntity.Campus });
    
        if (isGuid(schoolId)) {
            try {
                const schoolLicenseRes = await this.service.getSchoolLicenseTypes(schoolId);
                this.setState({ masterGSLicenses: schoolLicenseRes.grapeSEEDLicenses });
    
                if (isGuid(campusId)) {
                    const campusLicenseRes = await this.campusService.getCampusLicenseTypes(schoolId, campusId);
                    this.setState({ 
                        masterCampusGSLicenses: campusLicenseRes,
                        currentCampusGSLicenses: campusLicenseRes 
                    });
                }
            } catch (error) {
                console.error("Error fetching license types:", error);
            }
        }
    
        if (this.props.isNewCampus) {
            const newCampusLicenses = this.getLicensefromSchoolandCampus();
            this.setState({ 
                masterCampusGSLicenses: newCampusLicenses,
                currentCampusGSLicenses: newCampusLicenses 
            });
        }
    }

    rederNexusLicense () {
        const roles = ['SystemAdmin', 'SchoolAdmin', 'RegionAdmin', 'AccountManager']
        return roles.includes(heightestRole);
    }

    handleModalOk = () => {
        this.setState({ isDisableModalVisible: false });
        this.props.form.setFieldsValue({disabled: true})
    };
    
    handleModalCancel = () => {
        this.setState({ isDisableModalVisible: false });
        this.props.form.setFieldsValue({disabled: false})
    };

    showHideDisableModal = (value) => {
        this.setState({ isDisableModalVisible: value })
    }

    valuetoshow = (model) => {
        model.nexusLicenseType = this.state.currentCampusGSLicenses;
        return model.nexusLicenseType;
    }

    getPopUpDialogBox = () => {
        const footer = fmtMsg({ id: SchoolLocale.OnCampusDisableWarningFooter});
        const title = fmtMsg({ id: SchoolLocale.OnCampusDisableWarningTitle});
        return (
            <>
                <div>
                    <Modal
                        title={title}
                        visible={this.state.isDisableModalVisible}
                        cancelButtonProps={{ hidden: true }}
                        destroyOnClose={true}
                        className="invalid-max-unit-modal"
                        closable={false}
                        footer={[
                            <div className="invalid-max-unit-modal__modal-fotter-msg">
                                <span className='invalid-max-unit-modal__footer-message'>{footer}</span>
                                <Button
                                    className="ant-btn ant-btn-primary"
                                    key="Yes"
                                    onClick={this.handleModalOk}
                                >
                                    {fmtMsg({ id: SchoolLocale.OnCampusDisableWarningActionOne})}
                                </Button>
                                <Button
                                        className="ant-btn ant-btn-primary"
                                        key="Cancel"
                                        onClick={this.handleModalCancel}
                                    >
                                    {fmtMsg({ id: SchoolLocale.OnCampusDisableWarningActionTwo})}
                                </Button>
                            </div>
                            ]}
                        >
                            <div>
                                <ul>
                                    <li>{fmtMsg({ id: SchoolLocale.OnCampusDisableLineOne})}</li>
                                    <li>{fmtMsg({ id: SchoolLocale.OnCampusDisableLinetwo})}</li>
                                    <li>{fmtMsg({ id: SchoolLocale.OnCampusDisableLinethree})}</li>
                                </ul>
                            </div>
                    </Modal>
                </div>
            </>
        )
    }

    getErrorModal = (campusUpdateErrorStatus: CampusUpdateStatusModel, updateCampusLicenseError) => {

        let title = fmtMsg({ id: GSAdminLocale.CampusEditingErrorLicenseTypeAlreadyInUse});
        let footer = fmtMsg({ id: GSAdminLocale.CampusEditingInvalidLicenseTypeModalMsg });
        return (
            <div>
                <Modal
                    onCancel={e => { updateCampusLicenseError(null); }}
                    title={title}
                    visible={true}
                    cancelButtonProps={{ hidden: true }}
                    destroyOnClose={true}
                    className="invalid-max-unit-modal"
                    footer={[
                        <div className="invalid-max-unit-modal__modal-footer-msg">
                            <span>{footer}</span>
                            <Button
                                className="ant-btn ant-btn-primary"
                                key="ok"
                                onClick={e => { updateCampusLicenseError(null); }}
                            >
                                {fmtMsg({ id: GSAdminLocale.CommonButtonOk })}
                            </Button>
                        </div>
                    ]}
                >
                    <div className="invalid-max-unit-modal__content">
                        {campusUpdateErrorStatus.nexusLicensesInUse.length >
                            0 && (
                                <div className="invalid-max-unit-modal__section-gs">
                                    <h3>
                                        {fmtMsg({ id: GSAdminLocale.SettingsAllowGSMaxUnit })}
                                    </h3>
                                    <Table
                                        key={"table-ls"}
                                        dataSource={ campusUpdateErrorStatus.nexusLicensesInUse }
                                        pagination={false}
                                    >
                                        <Table.Column
                                            width={"40%"}
                                            title={fmtMsg({ id: GSAdminLocale.CampusLicenseChangeWarningColumnClass })}
                                            dataIndex="schoolClass"
                                            key="schoolClassId"
                                        />
                                        <Table.Column
                                            width={"40%"}
                                            title={fmtMsg({ id: GSAdminLocale.SettingInvalidLcenseTypeModal })}
                                            render={(data) => LicenseTypeValueNameMap[data.licenseType]}
                                            key="licenseType"
                                        />
                                    </Table>
                                </div>
                            )}
                    </div>
                </Modal>
            </div>
        )
    }
    
    render() {
        const licenseOptions = this.getLicenseOptions();
        const { form, handleSubmit, children, model = {}, campusUpdateErrorStatus, updateCampusLicenseError } = this.props;
        const renderFormItem = FormHelper.renderFormItem;
        const options = {
            formItemProps: { label: null },
            decoratorOptions: { valuePropName: 'checked' }
        }
        const { regionId } = this.props.match.params;
        const tagList = this.props.tagList[`${regionId}-${TagEntity.Campus}`] || [];
        const hasTagManagerPermission = GLGlobal.isActionValid(GSAdminAction.SchoolTagsManage);
        const invalidLicenseChangePopUp = campusUpdateErrorStatus && !campusUpdateErrorStatus.isValid && this.getErrorModal(campusUpdateErrorStatus, updateCampusLicenseError);
        return (
            <>
                <GLForm form={form} onSubmit={onSubmit(this, handleSubmit)} onChange={this.onFormChange}>
                    <FormItemsLayout colTotal={2}>
                        {renderFormItem(form, SchoolLocale.CampusName, 'name', <Input />, model.name, true, lengthValider(SchoolLocale.CampusName, 120))}
                        {GLGlobal.isActionValid(GSSchoolAction.EditCampusGSNumber) && renderFormItem(form, SchoolLocale.CampusGSNumber, 'gsNumber', <Input />, model.gsNumber, false, lengthValider(SchoolLocale.CampusGSNumber, 100))}
                        {renderFormItem(form, SchoolLocale.CampusPhone, 'phone', <Input />, model.phone, true, lengthValider(SchoolLocale.CampusPhone, 30))}
                        {renderFormItem(form, SchoolLocale.CampusFax, 'fax', <Input />, model.fax, false, lengthValider(SchoolLocale.CampusFax, 30))}
                        <React.Fragment>
                            {renderFormItem(form, SchoolLocale.CampusPostalCode, 'postalCode', <Input />, model.postalCode, true, lengthValider(SchoolLocale.CampusPostalCode, 30))}
                        </React.Fragment>
                        {( hasTagManagerPermission && renderFormItem(
                            form,
                            GSAdminLocale.SchoolModelTags,
                            "tags",
                            <Select
                                size="large"
                                {...alignPop()}
                                onChange={this.onFormChange}
                                mode="multiple"
                                maxTagCount={2}
                                optionFilterProp="children"
                            >
                                {tagList.map(tag => (
                                    <Select.Option key={tag.id} value={tag.id}>{tag.name}</Select.Option>
                                ))}
                            </Select>,
                            model && model.tags ? model.tags.map((x) => x.id) || [] : [],
                            false,
                            []
                        )
                    )}
                    </FormItemsLayout>
                    {renderFormItem(extendForm(form), SchoolLocale.CampusAddress, 'fullAddress', <TextArea rows={3} className='address' />, model.fullAddress, true, lengthValider(SchoolLocale.CampusAddress, 250))}
                    {this.rederNexusLicense() && <div className="nexus-license-box">
                        <div style={{width: "100%"}}>
                            <div className='nexus-license-box__text'>
                                <p>{fmtMsg({ id: SchoolLocale.CampusNexusLicenseMessage })}
                                </p>
                            </div>
                            {
                                renderFormItem
                                (
                                    { ...form, ...options },
                                    LicenseTypeLocale.Nexus,
                                    "nexusLicenseType",
                                    <Checkbox.Group
                                        className='nexus-checkbox'
                                        options={licenseOptions}
                                        value = {this.valuetoshow(model)}
                                        onChange={this.handleCheckboxChange}
                                        >
                                    </Checkbox.Group>,
                                    model.nexusLicenseType
                                )
                            }
                        </div>
                    </div>}
                    {model.id && GLGlobal.isActionValid(GSSchoolAction.CampusAnnualPrepComplete)
                        && renderFormItem({ ...form, ...options }, SchoolLocale.BulkAnnualPreparationCompleteCheck, 'annualPrepComplete',
                        <Checkbox onChange={(e) => { model.annualPrepComplete = e.target.checked }}>
                            <FormattedMessage id={SchoolLocale.BulkAnnualPreparationCompleteCheck} />
                        </Checkbox>, model.annualPrepComplete)
                    }
                    {model.id && renderFormItem({ ...form, ...options }, SchoolLocale.ClassDisabled, 'disabled',
                        <Checkbox
                        onChange={(e) => this.showHideDisableModal(e.target.checked)}
                        disabled={ClassHelper.canEditRequiredFieldAnnualPrepComplete(model.annualPrepComplete)}
                        >
                            <FormattedMessage id={SchoolLocale.CampusDisabled} />
                        </Checkbox>,model.disabled)}
                    {children}
                </GLForm>
            {invalidLicenseChangePopUp}
            {this.state.isDisableModalVisible && this.getPopUpDialogBox()}
            </>
        );
    }
}
function lengthValider(localeId, rigthVal) {
    return [FormHelper.ruleForCompareLength(localeId, ComparisonOperator.LessOrEqualsThan, rigthVal)];
}
function addressValider(form, id) {
    return textareaValider(form, id).concat(lengthValider(SchoolLocale.CampusAddress1, 250));
}
