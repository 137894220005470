import * as React from 'react'
import { Component } from 'react'
import { RouteComponentProps } from "react-router-dom";
import { SecondaryTitle, SubmitBtns } from '../../../components';
import { CampusForm } from './components';
import { withRouter, GLGlobal, connect } from 'gl-commonui';
import { create, goToList } from '../../../states/school/campus';
import { SchoolLocale } from '../../../locales/localeid';
import { GSSchoolAction } from '../../../util/enum';

interface CampusNewPageProps {
    create: (d) => void
    goToList: () => void
    schoolId: string 
}

@withRouter
@connect(null, {
    create,
    goToList
})
export class CampusNewPage extends Component<RouteComponentProps<any> & CampusNewPageProps> {
    render() {
        const { create, goToList } = this.props;
        return (
            <div className='content-layout campus-crud' >
                <div>
                    <SecondaryTitle title={SchoolLocale.CampusNewTitle} />
                    <CampusForm handleSubmit={createPrimaryBy(create, this.props)} isNewCampus={true} >
                        <SubmitBtns onCancel={goToList} submitAction={GSSchoolAction.SaveCampus} />
                    </CampusForm>
                                    </div>
            </div>
        )
    }
}
function createPrimaryBy(callback, props) {
    return (d) => {
        callback(d, true);
    }
}